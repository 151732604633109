import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TableWithPagination from "../../components/TableWithPagination";
import api from "../../services/api";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Title from "../../components/Title";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Tooltip from "@material-ui/core/Tooltip";
import DateRangePicker from "../../components/DateRangePicker";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Archive } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    rowGap: "1rem",
  },
  tableContainer: {
    backgroundColor: "#fff",
    padding: theme.spacing(2),
    position: "relative",
    overflow: "auto", // Adiciona overflow para conteúdo grande
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  searchBar: {
    marginBottom: 0,
    marginTop: 0,
    flex: 1, // Faz o TextField se expandir para ocupar espaço restante
    minWidth: 200, // Define largura mínima para evitar colapso
  },
  truncatedText: {
    display: "inline-block",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
    },
    tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1),
    },
    card: {
      padding: "20px",
      textAlign: "center",
      color: theme.palette.text.secondary,
      },
}));

const UserTicketsList = () => {
  const history = useHistory();
  const classes = useStyles();

  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ticketsData, setTicketsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userName, setUserName] = useState("");
  const [numOpenTickets, setNumOpenTickets] = useState(0);
  const [numClosedTickets, setNumClosedTickets] = useState(0);
  const [filteredTickets, setFilteredTickets] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const fetchUserTickets = async () => {
      try {
        const response = await api.get(`/reports/user-tickets/${id}`);
        const { userName, tickets } = response.data;

        const formattedTickets = tickets.map((ticket) => ({
          ...ticket,
          status: translateStatus(ticket.status),
          etiquetasFormatted: formatEtiquetas(ticket.etiquetas),
          ultimaAtualizacao: formatDate(ticket.ultimaAtualizacao),
          filasFormatted: formatFilas(ticket.fila),
          lastMessage: ticket.ultimaMensagem,
        }));

        const sortedTickets = formattedTickets
          .slice()
          .sort(
            (a, b) =>
              new Date(b.ultimaAtualizacao) - new Date(a.ultimaAtualizacao)
          );

        const numClosedTickets = formattedTickets.filter(
          (ticket) => ticket.status === "Fechado"
        ).length;
        const numOpenTickets = formattedTickets.filter(
          (ticket) => ticket.status === "Aberto"
        ).length;

        setUserName(userName);
        setTicketsData(sortedTickets);
        setFilteredTickets(sortedTickets);
        setNumClosedTickets(numClosedTickets);
        setNumOpenTickets(numOpenTickets);
      } catch (error) {
        console.error("Erro ao obter os tickets do usuário:", error);
      }
    };

    fetchUserTickets();
  }, [id]);

  useEffect(() => {
    setFilteredTickets(ticketsData);
  }, [ticketsData]);

  useEffect(() => {
    filterTickets();
  }, [searchQuery, startDate, endDate]);

  useEffect(() => {
    const numClosedTickets = filteredTickets.filter(
      (ticket) => ticket.status === "Fechado"
    ).length;
    const numOpenTickets = filteredTickets.filter(
      (ticket) => ticket.status === "Aberto"
    ).length;

    setNumClosedTickets(numClosedTickets);
    setNumOpenTickets(numOpenTickets);
  }, [filteredTickets]);

  const translateStatus = (status) => {
    return status === "open" ? "Aberto" : "Fechado";
  };

  const formatEtiquetas = (etiquetas) => {
    if (!etiquetas || etiquetas.length === 0) {
      return [];
    }

    return etiquetas.map((etiqueta, index) => (
      <span
        key={index}
        style={{
          backgroundColor: etiqueta.cor,
          color: "white",
          padding: "2px 5px",
          borderRadius: "3px",
          marginRight: "5px",
        }}
      >
        {etiqueta.nome}
      </span>
    ));
  };

  const formatFilas = (fila) => {
    if (!fila) {
      return "";
    }

    return (
      <span
        style={{
          backgroundColor: fila.cor,
          color: "white",
          padding: "2px 5px",
          borderRadius: "3px",
          marginRight: "5px",
        }}
      >
        {fila.nome}
      </span>
    );
  };

  const formatDate = (date) => {
    return format(new Date(date), "dd/MM/yyyy HH:mm");
  };

  const filterTickets = () => {
    let filtered = ticketsData;

    if (searchQuery.trim()) {
      const searchTerm = searchQuery.toLowerCase().trim();
      filtered = filtered.filter((ticket) => {
        if (ticket.id.toString().includes(searchTerm)) {
          return true;
        }

        if (
          ticket.fila &&
          ticket.fila.nome.toLowerCase().includes(searchTerm)
        ) {
          return true;
        }

        if (
          ticket.etiquetasFormatted.find((etiqueta) =>
            etiqueta.props.children.toLowerCase().includes(searchTerm)
          )
        ) {
          return true;
        }

        return false;
      });
    }

    if (startDate && !endDate) {
      const startDateFull = moment(startDate, "YYYY-MM-DD");

      filtered = filtered.filter((ticket) => {
        if (
          moment(ticket.ultimaAtualizacao, "DD/MM/YYYY HH:mm").isSameOrAfter(
            startDateFull
          )
        ) {
          return true;
        }
      });
    }

    if (endDate && !startDate) {
      const endDateFull = moment(endDate, "YYYY-MM-DD");

      filtered = filtered.filter((ticket) => {
        const ticketDate = moment(ticket.ultimaAtualizacao, "DD/MM/YYYY HH:mm");
        return ticketDate.isSameOrBefore(endDateFull, "day");
      });
    }

    if (startDate && endDate) {
      const startDateFull = moment(startDate, "YYYY-MM-DD");
      const endDateFull = moment(endDate, "YYYY-MM-DD");

      filtered = filtered.filter((ticket) => {
        const ticketDate = moment(ticket.ultimaAtualizacao, "DD/MM/YYYY HH:mm");
        return ticketDate.isBetween(startDateFull, endDateFull, "day", "[]");
      });
    }

    setFilteredTickets(filtered);
    return filtered;
  };

  const columns = [
    { field: "id", headerName: "ID", minWidth: 100 },
    { field: "status", headerName: "Status", minWidth: 100 },
    { field: "filasFormatted", headerName: "Fila", minWidth: 200 },
    { field: "etiquetasFormatted", headerName: "Etiquetas", minWidth: 200 },
    {
      field: "lastMessage",
      headerName: "Ulti. Mensagem",
      minWidth: 150,
      render: (value) => (
        <Tooltip title={value} classes={{ tooltip: classes.tooltip }}>
          <span className={classes.truncatedText}>{value}</span>
        </Tooltip>
      ),
    },
    {
      field: "ultimaAtualizacao",
      headerName: "Ulti. Atualização",
      minWidth: 150,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row) => {
    history.push(`/tickets/${row.id}`);
  };

  const exportToCSV = () => {
    const headers = [
      { label: "ID", key: "id" },
      { label: "Status", key: "status" },
      { label: "Fila", key: "fila" },
      { label: "Etiquetas", key: "etiquetas" },
      { label: "Ulti. Atualização", key: "ultimaAtualizacao" },
      { label: "Total.Abertas", key: "totalOpen" },
      { label: "Total.Fechadas", key: "totalClosed" },
    ];

    const csvData = filteredTickets.map((ticket) => ({
      id: ticket.id,
      status: ticket.status,
      fila: ticket.fila ? ticket.fila.nome : "",
      etiquetas: ticket.etiquetasFormatted
        .map((etiqueta) => etiqueta.props.children)
        .join(", "),
      ultimaAtualizacao: ticket.ultimaAtualizacao,
      totalOpen: filteredTickets.filter((t) => t.status === "Aberto").length,
      totalClosed: filteredTickets.filter((t) => t.status === "Fechado").length,
    }));

    return (
      <Tooltip title="Exportar CSV">
        <CSVLink data={csvData} headers={headers} filename={"tickets.csv"}>
          <Button variant="contained" color="primary">
            <Archive />
          </Button>
        </CSVLink>
      </Tooltip>
    );
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
        <Button onClick={() => history.goBack()}>Voltar</Button>
        <Title>Tickets por Usuário</Title>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.card}>
            <Typography variant="h5" component="h2">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                p="10"
              >
                <Typography variant="body1">Usuário</Typography>
                <Typography variant="body1">{userName}</Typography>
              </Box>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.card}>
            <Typography variant="h5" component="h2">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="body1">Atd. Acontecendo</Typography>
                <Typography variant="body2">{numOpenTickets}</Typography>
              </Box>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.card}>
            <Typography variant="h5" component="h2">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="body1">Atd. Realizados</Typography>
                <Typography variant="body2">{numClosedTickets}</Typography>
              </Box>
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Paper className={classes.tableContainer}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1rem 0" }}>
          <MainHeaderButtonsWrapper>
            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", columnGap: "10px" }}>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
              <TextField
                className={classes.searchBar}
                label="Pesquisar"
                placeholder="ID, Etiqueta, Fila"
                variant="outlined"
                type="search"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "gray" }} />
                    </InputAdornment>
                  ),
                }}
              />
              {exportToCSV()}
            </div>
          </MainHeaderButtonsWrapper>
        </div>
        <TableWithPagination
          columns={columns}
          data={filteredTickets}
          rowsPerPageOptions={[10, 25, 50]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={filteredTickets.length}
          onRowClick={handleRowClick}
          customizeColumns={true}
        />
      </Paper>
    </div>
  );
};

export default UserTicketsList;
