import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Board from "react-trello";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "6px 10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    fontSize: "0.7rem",
    cursor: "pointer"
  },
}));

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();

  const [lanes, setLanes] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [file, setFile] = useState({ lanes: [] });

  useEffect(() => {
    fetchInitialData();
    fetchLanes();
  }, []);

  const initializeKanban = async () => {
    try {
      const response = await api.post("/kanban/init");
    } catch (error) {
      console.error("Erro ao inicializar Kanban:", error);
    }
  };

  const fetchInitialData = async () => {
    try {
      const response = await api.get("/kanban/tickets");
      const fetchedTickets = response.data || [];
      setTickets(fetchedTickets);
    } catch (error) {
      console.error("Erro ao carregar os tickets do Kanban.", error);
      toast.error("Erro ao carregar os tickets do Kanban.");
    }
  };

  const fetchLanes = async () => {
    try {
      const response = await api.post("/kanban/init");
      const fetchedLanes = response.data.lanes || [];
      setLanes(fetchedLanes);
    } catch (error) {
      console.error("Erro ao carregar as lanes do Kanban.", error);
      toast.error("Erro ao carregar as lanes do Kanban.");
    }
  };

  const popularCards = () => {
    /*if (tickets.length === 0) {
      console.warn("Nenhum ticket encontrado.");
      return;
    }
    */
    const initialLane = {
      id: 0,
      title: i18n.t("Em aberto"),
      label: "0",
      disallowAddingCard: true,
      droppable: false,
      cards: tickets.map((ticket) => ({
        id: ticket.id.toString(),
        label: "Ticket nº " + ticket.id.toString(),
        description: (
          <div>
            <p>
              {ticket.contact.number}
              <br />
              {ticket.lastMessage}
            </p>
            <button
              className={classes.button}
              onClick={() => handleCardClick(ticket.id)}
            >
              Ver Ticket
            </button>
          </div>
        ),
        title: ticket.contact.name,
        draggable: true,
        href: "/tickets/" + ticket.id,
      })),
    };

    const additionalLanes = lanes.map((lane) => {
      const laneTickets = lane.ticketLanes.map(
        (ticketLane) => ticketLane.ticket
      );

      return {
        id: lane.id.toString(),
        title: lane.title,
        label: laneTickets.length.toString(),
        editLaneTitle: true,
        disallowAddingCard: true,
        cards: laneTickets.map((ticket) => ({
          id: ticket.id.toString(),
          label: "Ticket nº " + ticket.id.toString(),
          description: (
            <div>
              <p>
              <span style={{ fontWeight: 'bold', color: ticket.status === 'open' ? 'green' : 'red' }}>
            {ticket.status === 'open' ? 'Aberto' : 'Fechado'}
          </span>
                <br/>
                <br/>
                {ticket.contact.number}
                <br />
                {ticket.lastMessage}
              </p>
              <button
                className={classes.button}
                onClick={() => handleCardClick(ticket.id)}
              >
                Ver Ticket
              </button>
            </div>
          ),
          title: ticket.contact.name,
          draggable: true,
          href: "/tickets/" + ticket.id,
        })),
        style: { backgroundColor: lane.color || "#ddd", color: "white" },
      };
    });

    setFile({ lanes: [initialLane, ...additionalLanes] });
  };

  const handleCardClick = (uuid) => {
    history.push("/tickets/" + uuid);
  };

  useEffect(() => {
   // if (tickets.length > 0) {
      popularCards();
   // }
  }, [tickets, lanes]);

  const handleCardMove = async (sourceLaneId, targetLaneId, cardId, index) => {
    try {
      if (targetLaneId != 0) {
        await api.put(`/kanban/cards/${cardId}/move`, {
          sourceLaneId,
          targetLaneId,
        });
        toast.success("Movimento de card processado com sucesso!");
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
      //toast.error("Erro ao processar movimento de card.");
    }
  };

  const handleAddNewLane = async (laneData) => {
    const { title } = laneData;

    try {
       await api.post("/kanban/lanes", {
        title,
        position: lanes.length,
      });
      window.location.reload()
    } catch (error) {
      console.error("Erro ao criar nova lane:", error);
    }
  };
  const handleLaneDelete = async (laneId) => {
    try {
      const response = await api.delete(`/kanban/lane/delete/${laneId}`);
      toast.success("Lane removida");
    } catch (error) {}
  };

  const handleCardDelete = async (cardId, laneId) => {
    if (laneId === 0) {
      return;
    }
    try {
      await api.delete(`/kanban/card/delete`, {
        data: { cardId: parseInt(cardId), laneId: parseInt(laneId) }, // Garante que são números inteiros
      });
      toast.success("Card removido");
    } catch (error) {
      console.error("Error deleting card:", error);
    }
  };

  const handleLaneUpdate = async (laneId, data) => {   
    try {
      await api.post(`/kanban/lane/update/${laneId}`, {
        data: data,
      })
    }catch(error) {
    }

  }
  

  return (
    <div className={classes.root}>
      <Board
        data={file}
        editable
        canAddLanes
        draggable
        onLaneUpdate={handleLaneUpdate}
        onCardMoveAcrossLanes={handleCardMove}
        onLaneAdd={handleAddNewLane}
        onLaneDelete={handleLaneDelete}
        onCardDelete={handleCardDelete}
        style={{ backgroundColor: "rgba(252, 252, 252, 0.03)" }}
      />
    </div>
  );
};

export default Kanban;
